(function () {
  'use strict';

  angular.module('module.sales').directive('ttAddressForm', [
    function () {
      return {
        restrict: 'A',
        templateUrl: 'templates/sales.address.directive.template.html',
        controllerAs: 'addressVm',
        scope: {
          address: '=',
          locale: '=',
          addressRequired: '=?',
        },
        controller: ['AddressService', controller],
        bindToController: true,
      };

      /**
       * @param AddressService
       * @param CurrencyService
       */
      function controller(AddressService) {
        /* jshint validthis: true */
        var vm = this;

        vm.showManualEntryFunc = showManualEntryFunc;
        vm.populate = populate;
        vm.postcodeFieldName = vm.locale === 'US' ? 'ZIP Code' : 'Postcode';

        init();

        /**
         * Init the directive
         */
        function init() {
          vm.showManualEntry = false;

          if (!vm.address) {
            vm.address = {
              line1: null,
              line2: null,
              line3: null,
              city: null,
              county: null,
              country: null,
              postcode: null,
            };
          }
        }

        /**
         * Show the manual entry form
         */
        function showManualEntryFunc() {
          vm.country = vm.locale === 'UK' ? 'Great Britain' : 'USA';
          vm.address.country = vm.address.country ? vm.address.country : vm.country;
          vm.showManualEntry = true;
        }

        /**
         * Handle Capture+ populate
         *
         * @param {object} capturePlusAddress
         */
        function populate(capturePlusAddress) {
          vm.address = AddressService.capturePlusToAddress(capturePlusAddress);
        }
      }
    },
  ]);
})();
